.ant-table {
  font-family: inherit !important;
}
.ant-table-wrapper .ant-table-container {
  border-radius: 0;
}
.post--component-table {
  margin-bottom: 10px;
}
th.ant-table-cell {
  min-width: 100px;
}
.post--component-table .ant-table-row td {
  padding: 4px;
}
.post--component-table {
  min-height: 100px;
}
.post--component-table .btn-fullscreen {
  left: 40px;
  bottom: 10px;
}
.post--component-table .table {
  overflow: hidden;
  overflow-x: auto;
}
.post--component-table .ant-table-column-title {
  width: 40px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}
.post--component-table .table .ant-table-row div {
  text-overflow: ellipsis;
  overflow: hidden;
}

.post--component-table .table .ant-table-header-column {
  min-width: 80px !important;
}
.post--component td.ant-table-column-sort {
  background: transparent !important;
}



.post--component .ant-table-header-column .ant-table-selection {
  display: none !important;
}
.post--component .ant-table-column-title {
  width: 75px;
  font-size: 1;
}
.post--component .ant-table-row td {
  width: auto !important;
  min-width: 40px !important;
  overflow: hidden;
  position: relative;
}
.ant-spin-nested-loading {
  font-family: inherit !important;
  font-size: inherit !important;
}
.post--component-table .symbol {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0 auto;
}
.post--component-table .circle-wrapper {
  width: 30px;
  height: 30px;
  right: 0;
  left: 0;
  top: 0;
  position: absolute;
}
.post--component-table .circle {
  text-indent: -9999px;
  display: block;
  opacity: 0.8;
  max-width: 20px;
  max-height: 20px;
  border-radius: 50%;
  background: #49c348;
  margin: 0 auto 0 auto;
  cursor: pointer;
}
.post--component-table .square {
  text-indent: -9999px;
  display: block;
  opacity: 0.9;
  max-width: 20px;
  max-height: 20px;
  background: #49c348;
  margin: 0 auto 0 auto;
  cursor: pointer;
}
.post--component-table .bar {
  height: 10px;
  opacity: 1;
  background: #6bc4f8;
  display: inline-block;
  cursor: pointer;
}
.post--component-table .bar-wrapper {
  width: 100%;
  line-height: 0;
  background: #f9f9f9;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}
.post--component-table .stacked-bar-wrapper {
  width: 100%;
  line-height: 0;
  background: #f9f9f9;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}
.post--component-table .bar-segment {
  float: left;
  height: 10px;
  border-right: 1px solid #fff;
  opacity: 0.7;
}
.post--component-size--large.post--component-table {
  padding: 0 20px;
}
.ant-col-sm-12 .post--component-table.post--component-size--medium {
  overflow: hidden;
}
.ant-table-thead > tr > th {
  background: #fafafa;
}




.ant-tooltip-arrow-content {
  background-color: #fff !important;
}

.ant-table-summary {
  color: #111;
  font-size: 12px;
  text-align: center;
}

.ant-table tfoot > tr > td {
  padding: 8px !important;
}

.component-dimensions {
  margin-bottom: 20px;
}
.component-dimension {
  border-bottom: 1px solid #ddd;
  padding: 5px 0px;
  margin-top: 5px;
  position: relative;
}
.component-measure {
  border: 1px solid #ddd;
  background: #fff;
  padding: 5px 10px;
  margin: 5px 0;
  position: relative;
}
.component-measure .anticon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

td .layout--region {
  margin-bottom: 0px !important;
  margin-top: 10px;
}
td .layout--region .post--component-button {
  margin-bottom: 0px;
}
