.pointer-component {
  max-width: 900px;
  margin: 10px auto;
  border-radius: 5px;
  padding: 0 40px;
  color: #777;
}
.pointer-component .content {
  padding: 5px 40px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: rgba(0,0,0,0.01);
}