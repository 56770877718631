.style-settings .content {
    padding: 10px 15px;
}
.style-settings-parent-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #111 !important;
}
.style-settings-parent-tabs .ant-tabs-tab-btn {
    font-size: 13px !important;
}
.style-settings-parent-tabs .ant-tabs-ink-bar {
    background: #111 !important;
}
.style-settings-parent-tabs {
    font-weight: bold;
}
.style-settings-child-tabs {
    font-weight: normal;
}
.style-settings-child-tabs .ant-tabs-tab-btn {
    font-size: 11px !important;
}
.style-settings-child-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #111 !important;
}
.style-settings-child-tabs .ant-tabs-ink-bar {
    background: #999 !important;
}