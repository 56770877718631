.layout--region .post--component-card {
  margin-bottom: 0px;
}
.card {
  position: relative;
  border-bottom: 2px solid #ddd;
}
.card img {
  width: 100%;
}
.card p {
  margin: 0;
}
.dark-mode .card {
  background: #1e2127;
  border-bottom: 1px solid #555;
  color: #999;
}
.post--component-card .component--content {
  position: relative;
}
.post--component-card .component-empty {
  text-align: center;
  width: 100%;
  font-size: 100px;
  margin-bottom: 10px;
}

.big-number {
  font-weight: 700;
  font-size: 30px;
  color: #111;
  white-space: nowrap;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dark-mode .big-number {
  color: #ddd;
}
.dimension-edit {
  position: absolute;
  top: 10px;
  right: 32px;
}
.card--components {
  position: relative;
}
.card--components .btn-add {
  display: inline-block;
  position: relative;
  margin: 0 !important;
}
.card .component--title {
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-clickable {
  cursor: pointer;
}
.card-clickable:hover {
  opacity: 0.8;
}
.card-highlight {
  border: 2px solid red !important;
}
.card .post--component-layout.post--component-size--large {
  margin-bottom: 0px !important;
}
.layout--region .post--component-card .layout--region {
  margin-bottom: 0px !important;
}
.scroll-item:last-child {
  margin-right: 0 !important;
}