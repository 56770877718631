.field-input--item {
  margin: 0 0 2px 0;
}
.field-input--group {
  border-bottom: 1px solid #ddd;
  padding: 0px 0 20px 0;
  margin: 0px 0 5px 0;
  position: relative;
}
.field-input--group::before {
  content: attr(data-content);
  font-weight: bold;
  display: block;
  width: 100%;
}
.field-input--group:first-child::before {
  content: '';
}
.field-input--group:last-child {
  border-bottom: none;
  padding: 0;
  margin: 0;
}
.field-input--group:empty {
  display: none;
}

.ant-collapse-item,
.ant-collapse-content {
  border: none !important;
  background: #f9f9f9;
}
.post--component-drawer .ant-collapse-item {
  margin-bottom: 5px;
}
.ant-collapse-content .ant-select {
  background: #fff;
}
.editor-left-region .ant-collapse-content-box,
.post--component-drawer .ant-collapse-content-box {
  background: #f9f9f9;
  padding: 10px !important;
}

.field-input--collapse-panel .ant-collapse-header {
  background: #fff !important;
  padding: 8px 15px !important;
  font-size: 10px;
}
.field-input--collapse-panel .ant-collapse-content-box {
  padding: 0 0 5px 0 !important;
}

.field-input--collapse-child .ant-collapse-header {
  background: #f5f5f5 !important;
  border-bottom: 1px solid #ddd !important;
  font-size: 10px;
}

.field-input--collapse-child .ant-collapse-content-box {
  padding: 0 5px 10px 5px !important;
}

.field-input--collapse-child .field-input--collapse-panel .ant-collapse-header {
  background: #f1f1f1 !important;
  border-bottom: 1px solid #ddd !important;
}


.field-input--collapse-child
  .field-input--collapse-panel
  .field-input--collapse-child
  .ant-collapse-header {
  background: #f9f9f9 !important;
  border-bottom: none !important;
}

.field-input--collapse-child
  .field-input--collapse-panel
  .field-input--collapse-child
  .ant-collapse-header {
}
.field-input--collapse-child
  .field-input--collapse-panel
  .field-input--collapse-child
  .ant-collapse-content-box {
  padding: 0px !important;
}

.ant-collapse-header-text {
  width: 70%;
}
