@import url('https://fonts.googleapis.com/css?family=PT+Serif:400,700|Poppins:400,700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden !important;;
}

a { 
  text-decoration: none;
}
html {
  width: auto;
  height: auto;
}
.ant-table-thead > tr > th {
  color: #111;
}
.source--table td {
  padding: 10px 10px !important;
}
.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0 !important;
}
.ant-table-thead > tr:last-child > th:last-child {
  border-top-right-radius: 0 !important;
}

.source--table td .ant-btn {
  margin-left: 20px;
  float: right;
}
.ant-spin-dot-item {
  background-color: #222 !important;
}
.component-loader {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  left: 0;
}
.component-loader .ant-spin {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 30%;
}
.page {
  max-width: 1200px;
  padding: 40px !important;
  margin: 0 auto !important;
}
label {
  text-transform: uppercase;
  font-size: 11px;
  display: block;
  margin: 0 0 2px 0;
}
.account-banner-lgt .account-site {
  color: #eeeeee;
}
.account-banner-lgt {
  color: #eeeeee;
}
.account-banner-lgt h2 {
  color: #eeeeee !important;
}
.dashboard {
  position: relative;
  min-height: 90vh;
  padding-bottom: 60px;
}
small {
  font-size: 12px;
}
.ant-col {
  min-height: 0px;
}
.ant-pagination {
  margin: 0 0 20px 0;
}
.ant-form-item-explain {
  margin-top: 4px;
  font-size: 12px;
}
.ant-checkbox-inner {
  display: inline-block;
}
.ant-tabs h1,
.ant-tabs h2,
.ant-tabs h3,
.ant-tabs h4,
.ant-tabs h5 {
  margin-top: 15px;
}
h1,h2,h3,h4,h5,h6 {
  font-weight: 700;
}
