.author-wrapper .content {
  position: relative;
  height: 40px;
}
.author-pic {
  width: 30px;
  height: 30px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-size: contain;
  text-align: center;
  position: absolute;
  left: 0;
  top: 5px;
  cursor: pointer;
}
.author-pic .anticon {
  padding-top: 6px;
}
.author-info {
  position: absolute;
  left: 40px;
  top: 11px;
}
.author-name {
  color: inherit;
}
.ant-popover {
  max-width: 260px;
}
.author-handle {
  opacity: 0.5;
}
.author-handle a {
  color: #111;
}
