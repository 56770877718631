.global-nav {
  height: 50px;
  background: #fff;
  border-bottom: 1px solid #eee;
  z-index: 1000;
  position: relative;
}
.global-nav-mark {
  position: absolute;
  cursor: pointer;
  left: 8px;
  top: 10px;
  z-index: 100;
  height: 34px;
  width: 34px;
  display: block;
  background: url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center;
  background-size: contain;
  text-indent: -9999px;
}
.global-nav-prompt {
  position: absolute;
  left: 0;
  right: 0;
  top: 4px;
  font-size: 13px;
  color: #555;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;
}
@media(max-width: 700px) {
  .global-nav-prompt {
    display: none;
  }
}

.has-global-nav .post-template--map {
  background: #f9f9f9;
  width: 100%;
  height: calc(100% - 50px);
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 100;
}

.btn-edit-post {
  position: absolute;
  right: 50px;
  top: 10px;
}
.user-icon {
  position: absolute;
  right: 10px;
  top: 12px;
}
.btn-user-sign-in {
  position: absolute;
  right: 100px;
  top: 9px;
}
.btn-user-sign-up {
  position: absolute;
  right: 10px;
  top: 9px;
}
.post-menu {
  min-width: 240px;
}