.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000000;
  background: rgba(255, 255, 255, 1);
}
.dark-mode .loader {
  background: rgba(32, 44, 52, 1);
}
.mark {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
