.post-components-menu {
  font-size: 13px;
}
.post-components-menu a {
  color: #111;
}
.ant-tree-title {
  position: relative;
}
.ant-tree-title div.active::after {
  content: '.';
  text-indent: -9999px;
  border-bottom: 2px dashed #1677ff;
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 5px);
}
.post-components-menu--item {
  border-bottom: 1px solid #ddd;
  padding: 12px 0px 12px 20px;
  cursor: pointer;
  text-transform: capitalize;
  position: relative;
}

.post-components-menu--item-errors {
  border-bottom: 1px solid #ddd;
  padding: 12px 0px 12px 20px;
  cursor: pointer;
  text-transform: capitalize;
  position: relative;
}

.post-components-menu--item .delete-component {
  position: absolute;
  right: 25px;
  top: 15px;
}
