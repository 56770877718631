.map-template {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f9f9f9;
}
.user-auth .map-template {
    height: calc(100% - 50px) !important;
}
.has-global-nav .map-template {
    height: calc(100% - 50px);
    top: 50px;
}
.user-auth.editor-ui .map-template {
    height: 100% !important;
}
.map-template .map {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
}
.map-template .mapboxgl-canvas {
    width: 100% !important;
    height: 100% !important;
}
.map-template .mapboxgl-map {
    position: absolute !important;
    width: 100% !important;
    height: calc(100vh) !important;
}
.has-global-nav .map-template .mapboxgl-map { 
    height: calc(100vh - 50px) !important;
}
.map-template-layout-close {
    position: absolute;
    right: 10px;
    top: 10px;
}
.map-template-layouts-header h2 {
    margin: 0;
}
.map-template .post--component-text, 
.map-template .post--component-layout, 
.map-template .post--component-navLayout, 
.map-template .post--component-title, 
.map-template .post--component-chart, 
.map-template .post--component-map, 
.map-template .post--component-filter, 
.map-template .post--component-table, 
.map-template .post--component-card {
    padding: 0px !important;
}
.no-global-nav .map-template {
    height: 100%;
    top: 0;
}

@media(max-width: 600px) {
   .map-template-layout.collapse-enabled {
        width: 100% !important;
        left: 0 !important;
        top: auto !important;
        right: auto !important;
        bottom: 0 !important;
        z-index: 1000;
   }

}