
.feed-header {
  position: relative;
  background: #272D31 url(https://assets.akuko.io/akuko-callout-bg.jpg);
  height: 300px;
  min-width: 100%;
}
@media (max-width: 600px) {
  .feed-header {
    display: block;
  }
}
.feed-header--brand {
  position: absolute;
  top: 30px;
  left: 20px;
  height: 40px;
  width: 40px;
  text-indent: -9999px;
  filter:invert();
  background: url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center;
  background-size: contain;
  z-index: 30;
  display: none;
}
@media(max-width: 600px) {
  .feed-header--brand {
    display: block;
  }
}
.feed-header .callout {
  color: #fff;
  position: absolute;
  bottom: 30px;
  left: 40px;
  width: 80%;
  z-index: 30;
}
@media(max-width: 600px) {
  .feed-header .callout {
    left: 20px;
    width: 80%;
  }
}
.feed-header .callout h1 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 0px;
}
@media(max-width: 600px) {
  .feed-header .callout h1 {
    font-size: 30px;
  }
}
.feed-header .callout p {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0px;
}
.feed-header::after {
  content: '.';
  position: absolute;
  width: 100%;
  height: 300px;
  background: rgba(39,45,49,0.9);
  top: 0;
  left: 0;
  z-index: 20;
}

.feed-posts {
  padding: 40px 40px 0 40px;
  border-right: 1px solid #ddd;
  min-height: 100vh;
}
@media (max-width: 600px) {
  .feed-posts {
    padding: 20px 20px;
    min-height: auto;
  }
}
.feed-posts a h2 {
  color: #000;
}
.feed-posts p {
  font-family: 'PT Serif', serif;
  font-size: 18px;
  line-height: 1.6;
}
.feed-post:first-child {
  margin-top: 20px;
}
.feed-post:last-child {
  border-bottom: none;
}
.feed-post {
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.feed-post .content {
  max-width: 100%;
  position: relative;
  min-height: 140px;
}
.feed-post--thumb {
  width: 120px;
  height: 120px;
  float: right;
  background-size: cover !important;
  margin: 0 0 20px 40px;
}
.feed-post--thumb.no-thumb {
  background-size: 50% !important;
  opacity: 0.1;
}
.feed-post--date {
  font-size: 12px;
  color: #777;
  margin-bottom: 15px;
}
.feed-post--space-account {
  display: block;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
  background-size: contain !important;
  position: absolute;
  left: 0;
  font-family: 'Poppins', sans-serif;
  top: -3px;
}
.feed-post--space-name {
  text-transform: capitalize;
}
.feed-post--space {
  margin-bottom: 15px;
  color: #777;
}
.feed-post--space small {
  margin-left: 30px;
}


.feed--sidebar {
  position: relative;
}
.feed--sidebar .content {
  padding: 20px 40px 40px 40px;
  height: calc(100vh - 60px);
  position: fixed;
  max-width: 300px;
}
.has-global-nav .feed--sidebar .content {
  height: calc(100vh - 110px);
}
@media (max-width: 990px) {
  .has-global-nav .feed--sidebar .content {
    position: relative;
    border: none;
    height: auto;
    width: auto;
  }
  .feed--sidebar .content {
    position: relative;
    border: none;
    height: auto;
    width: auto;
  }
  .feed--sidebar .global-footer {
    margin-top: 40px !important;
  }
  .feed--sidebar .global-footer {
    left: -10px !important;
    margin-top: 100px !important;
    position: relative !important;
  }
  .feed-sidebar--authors {
    min-height: 200px;
  }
}
@media (max-width: 600px) {
  .feed--sidebar .content {
    padding: 20px;
  }
}

.feed-sidebar--sign-in {
  margin-bottom: 40px;
}
.feed-sidebar--about p {
  font-size: 13px;
  color: #555;
}
.feed-sidebar--about {
  margin-bottom: 50px;
}

.feed--sidebar .author-wrapper {
  width: 55px;
  height: 55px;
  display: inline-block;
}
.feed--sidebar .author-wrapper .content {
  border: none;
}
.feed--sidebar .author-wrapper .author-name,
.feed--sidebar .author-wrapper .author-handle {
  display: none;
}
.feed--sidebar .author-wrapper .author-pic {
 width: 45px;
 height: 45px;
}