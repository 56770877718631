.post--component-map {
  min-height: 100px;
  margin: 0 0 10px 0;
}
.post--component-map .coords {
  position: absolute;
  left: 100px;
  bottom: 10px;
  z-index: 1000;
  font-size: 9px;
}
.post--component-map .map {
  height: 500px;
  position: relative;
  outline: none;
  font-family: 'Poppins', sans-serif;
}
.post--component-map canvas {
  outline: none !important;
  animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.post--component-map .layer-selector {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 1000;
}

.post--component-map .withLayout .mapboxgl-popup-content {
  padding: 0px;
}

.post--component-map .mapboxgl-popup-content .layout--region {
  margin-bottom: 0px;
}

.post--component-map .mapboxgl-popup-content .btn-close-popup {
  z-index: 1;
}
.post--component-map .mapboxgl-popup-content .layout--content {
  margin-top: 0 !important;
}

.post--component-map .mapboxgl-popup-content .post--component-size--medium {
  padding: 0;
}

.post--component-map .mapboxgl-popup-content img {
  width: 100%;
}
.mapboxgl-popup-content h1 {
  font-family: 'Poppins';
  font-weight: 700;
}
.map {
  position: relative;
  outline: none;
}

.component--active-value {
  padding: 0 15px;
  font-size: 12px;
  margin: 0;
  color: #555;
  height: 10%;
  overflow: hidden;
  overflow-y: auto;
}
.map-layer-control button:hover {
  opacity: 0.7;
}
.btn-fullscreen {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 10000;
}
.mapboxgl-popup-content {
  line-height: 1.4;
}
.mapboxgl-popup-content b {
  color: #111;
}
.mapboxgl-popup-content a {
  color: grey;
}
.label-config-row {
  border-bottom: 1px solid #ddd;
  overflow: hidden;
  padding: 0 0 10px 0;
}

@media (max-width: 600px) {
  .post--component-0 .post--component-map.post--component-size--large {
    margin-top: -30px;
  }
}

.ant-col-sm-12 .post--component-map.post--component-size--small {
  max-width: 100% !important;
  padding: 0 20px;
}
.ant-col-sm-12 .post--component-map.post--component-size--medium {
  max-width: 100% !important;
  padding: 0 20px;
}
.ant-col-sm-12 .post--component-map.post--component-size--large {
  max-width: 100% !important;
}

.map-row-layer {
  font-size: 12px;
  margin: 0;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  position: relative;
}
.map-row-layer h4 {
  margin: 10px;
}
.map-row-layer label {
  font-size: 11px;
  margin: 0;
}
.map-row-layer--delete {
  position: absolute;
  right: 5px;
  top: 12px;
  font-size: 11px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.map-layer--field {
  margin: 0 0 20px 0;
}
.post--component-drawer .ant-tabs-nav .ant-tabs-tab {
  margin: 0 10px 0 0;
  padding: 5px 10px 5px 0;
  font-size: 12px;
}
.post--component-drawer .ant-tabs-tabpane {
  padding-top: 0px;
}
.ant-input-sm,
.ant-select-sm {
  font-size: 11px;
}
.map-color-rows {
  margin-top: 10px;
}
.map-color-row {
  margin: 5px 0;
  padding: 0 3px;
  font-size: 12px;
  max-height: 33px;
  overflow: hidden;
}

.color-picker .ant-select-dropdown-menu-item {
  padding: 0 12px;
}

.ant-col-sm-24 .post--component-map.post--component-size--large .filter-menu--slider,
.ant-col-sm-24 .post--component-map.post--component-size--large .filter-menu--select {
  left: 90px;
}
.filter-menu--select {
  margin-bottom: 10px;
}
.filter-menu--select label,
.filter-menu--slider label {
  font-family: 'Poppins', sans-serif;
}
.layer-label {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;
}
.layer-label--delete {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 11px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.layer-toggle-control {
  width: 200px;
  margin: 10px 0;
  z-index: 100;
  border-radius: 5px;
}
.layer-toggle {
  margin: 0 0 2px 0;
}
.map-controls {
  position: absolute;
  left: 12px;
  top: 12px;
  width: 295px;
  max-height: 90%;
  overflow-y: auto;
}
.map-controls .map-filter-control {
  padding: 10px;
  background: #fff;
}
.map-controls label {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  margin: 0;
  text-transform: uppercase;
}
.map-layer-control {
  padding: 10px;
  opacity: 0.9;
  border-radius: 3px;
}
.map-filter-control ul {
  padding: 0;
  list-style-type: none;
}
.map-filter-control ul label {
  text-transform: none;
  font-size: 12px;
  white-space: normal;
}
.map-filter-control .ant-checkbox-wrapper {
  width: 100%;
  overflow: auto;
}
.map-filter-control .parent-filter {
  font-weight: bold;
}
.map-layer-control label span {
  text-transform: capitalize !important;
}
.map-layer-control--layer label {
  white-space: normal;
  margin-left: 0px;
}
.map-controls .map-layer-control {
  padding: 10px;
  background: #fff;
}
.layer-column-control {
  margin: 5px 0;
}
.layer-id {
  color: #aaa;
  font-size: 11px;
}
.ant-tree-list-holder-inner {
  font-size: 12px;
}
.ant-tree .ant-tree-treenode {
  padding: 0;
}
.map-loader {
  font-size: 40px;
  position: absolute;
  top: 45%;
  width: 100%;
  text-align: center;
  z-index: 1000;
}
.btn-close-popup {
  position: absolute;
  right: 5px;
  font-size: 11px;
  color: #000;
  top: 5px;
}

.mapboxgl-ctrl-bottom-left {
  bottom: 0;
}
.mapboxgl-ctrl-bottom-left div {
  display: block !important;
}
.map-legend-container {
  margin: 20px auto;
}
.map-legend .map-legend--name {
  color: #121212;
  font-size: 11px;
  text-align: center;
  display: block;
  width: 50%;
  margin: 0 auto;
}
.vertical-legend--legend-item {
  display: block;
}
.vertical-legend--color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
.vertical-legend,
.quantity-horizontal-legend,
.heatmap-legend,
.symbol-legend {
  width: 50%;
  margin: 0px auto;
}
.heatmap-legend--colors,
.heatmap-legend--values,
.quantity-horizontal-legend--colors {
  width: 100%;
}
.heatmap-legend--colors,
.quantity-horizontal-legend--colors {
  display: inline-flex;
}
.heatmap-legend--color,
.quantity-horizontal-legend--color {
  flex: 1 100%;
  height: 10px;
  display: inline-block;
}
.heatmap-legend--values-low,
.heatmap-legend--values-high {
  font-size: 11px;
}
.heatmap-legend--values-low {
  float: left;
}
.heatmap-legend--values-high {
  float: right;
}
.quantity-horizontal-legend--value {
  margin-top: 20px;
  font-size: 11px;
  margin-right: -4px;
  float: right;
}
.quantity-horizontal-legend--start {
  margin-top: 20px;
  font-size: 11px;
  margin-right: -4px;
  float: left;
}
.symbol-legend--item {
  display: block;
}
.symbol-legend--item img {
  height: 100%;
  width: 100%;
  max-width: 15px;
  max-height: 15px;
  margin-right: 10px;
}
.quantity-horizontal-legend--color-transition {
  width: 1px;
  background-color: #ddd;
  height: 15px;
  z-index: 100;
}
.map-layer-pills {
  white-space: nowrap;
  text-align: center;
  margin: 5px auto 15px auto;
}
.map-layer-pills .ant-menu-submenu {
  color: #121212 !important;
  background: #fafafa;
  margin: 0 !important;
  padding: 0 20px !important;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd !important;
  border-right: 1px solid #ddd;
  line-height: 2.7;
}
.map-layer-pills ul {
  padding: 0;
  width: 600px;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
}
li:first-child .ant-menu-item {
  border-left: 1px solid #ddd !important;
}
.map-layer-pills .ant-menu-item::after {
  border: none !important;
}
.map-layer-pills li::before {
  content: '' !important;
}
.map-layer-pills .ant-menu-submenu::after {
  border: none !important;
}
@media (max-width: 700px) {
  .map-layer-pills ul {
    width: 320px;
  }
}
.map-layer-pills .ant-menu {
  border-bottom: none;
  background: none;
}
.map-layer-pills .ant-menu-item {
  color: #999;
  background: #fafafa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd !important;
  border-left: 1px solid #ddd;
  margin: 0 !important;
  line-height: 2.7;
}
.map-layer-pills li:nth-last-child(-n + 2) {
  border-right: 1px solid #ddd !important;
}
.map-layer-pills .ant-menu-item-active,
.map-layer-pills .ant-menu-item-selected {
  color: #000 !important;
  border-bottom: 1px solid #ddd !important;
  border-bottom-color: #ddd !important;
}
.map-layer-pills .ant-menu-submenu-title {
  color: #000;
}

.map-tour {
  position: absolute;
  width: 50%;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  z-index: 100;
  left: 30px;
  bottom: 30px;
}
.map-tour--item-description {
  font-family: 'PT Serif', serif;
  font-size: 16px;
  margin-bottom: 40px;
  line-height: 1.5;
}
.map-tour--item-next {
  position: absolute;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
}
.map-tour--item-prev {
  position: absolute;
  bottom: 30px;
  left: 30px;
  cursor: pointer;
}
.locate-control-false .mapboxgl-ctrl-top-left {
  display: none;
}
.withLayout.mapboxgl-popup {
  max-width: 500px !important;
}
.mapboxgl-popup-content {
  min-width: 90px;
  max-width: 400px;
  padding: 10px;
}
.mapboxgl-popup .mapboxgl-popup-content .post--component-map {
  min-height: auto !important;
  height: auto !important;
}
.mapboxgl-popup .post--component-map {
  margin: 0px;
}
.mapboxgl-popup-close-button {
  font-size: 24px;
  color: #aaa;
}