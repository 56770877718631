/* Post */
.post-page {
  min-height: 100vh;
  margin: 0 auto;
}
@media (max-width: 600px) {
  .post-page .post--content {
    padding-top: 0px;
  }
}
.post-in-space .post--content {
  padding-top: 0px;
}
.layout--region .btn-add {
  animation: none;
}


.post--content {
  max-width: 100%;
  overflow: hidden;
}
/* .post--content-space {
  min-height: 1vh;
} */
.post--editor-ui {
  border: 1px solid #eee;
  padding: 5px;
}
.post--component {
  position: relative;
 
}

/* Component */
.component--header {
  position: relative;
}
.component--controls {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #bbb;
  cursor: pointer;
}
.post--component-figure {
  position: relative;
  padding: 0 0 20px 0;
}
.post--hide-title-true .post--component-figure {
  padding: 0px;
}
.post--component-text,
.post--component-html,
.post--component-layout,
.post--component-navLayout,
.post--component-title,
.post--component-chart,
.post--component-map,
.post--component-filter,
.post--component-table,
.post--component-card {
  padding: 0 40px !important;
}
.post--component-figure .post--component-layout,
.post--component-figure .post--component-table,
.post--component-figure .post--component-chart {
  padding: 0 0 20px 0;
  position: relative;
}
.post--component-size--large.post--component-table {
  padding: 0 20px;
}
.component--brand {
  position: absolute;
  right: 12px;
  top: 13px;
  color: #bbb;
  font-size: 12px;
  text-transform: uppercase;
}
.component--legal {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: #bbb;
  font-size: 12px;
  letter-spacing: 1px;
}
.component--title {
  text-overflow: ellipsis;
  padding: 0;
  color: inherit;
  font-size: 18px;
}
.component--content {
  position: relative;
}
.post--component-map.post--component-size--large .component--title {
  margin-left: 30px;
}
.layout--region .post--component-map.post--component-size--large .component--title {
  margin-left: 0px;
}
@media (max-width: 600px) {
  .post--component-map.post--component-size--large .component--title {
    margin-left: 20px;
  }
}

.component--caption {
  color: #777;
  /* font-family: 'Poppins', sans-serif; */
  font-size: 13px;
  margin: 0px;
  padding: 10px 0;
}
.component--footer .component--brand {
  bottom: 0px;
  top: auto;
}
.btn-component {
  background: none;
  border: none;
  outline: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
  cursor: pointer;
}

.btn-add:hover {
  color: #555;
}
.btn-edit {
  position: absolute;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  background: rgba(255, 255, 255, 0.6);
  line-height: 1.8;
  top: 10px;
  height: 31px;
  left: 43px;
  z-index: 10000;
  font-size: 14px;
  z-index: 10;
}

.btn-delete {
  position: absolute;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  top: 10px;
  left: -20px;
  color: #aaa !important;
}
.btn-add {
  font-size: 16px;
  margin-left: 20px;
  display: inline-block;
  margin-bottom: 200px;
  padding: 5px;
}
@media (max-width: 900px) {
  .btn-add {
    margin-left: 10px;
  }
}
.post--component .btn-add {
  margin-left: 0;
}
.btn-options {
  position: absolute;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  top: 10px;
  left: 20px;
  font-size: 20px;
  z-index: 10;
}
@media (max-width: 900px) {
  .btn-options {
    left: 10px;
  }
}

.btn-export-options {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  top: -40px;
  right: 0px;
  position: absolute;
  font-size: 20px;
  z-index: 1000;
}

.btn-width-options {
  position: absolute;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  top: 10px;
  right: 20px;
  font-size: 20px;
  z-index: 1000;
}
@media (max-width: 900px) {
  .btn-width-options {
    right: 10px;
  }
  .btn-export-options {
    right: 5px;
  }
}

.component-empty {
  padding: 20px 0;
  font-size: 90px;
  color: #ddd;
  text-align: center;
  background: rgba(0, 0, 0, 0.02);
  height: 120px;
}
.post--component-number .component-empty,
.post--component-button .component-empty {
  height: 40px;
}
.post--component-size--small {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.post--component-size--medium {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}
.post--component-size--large .component--header {
  padding: 0 0 0 0;
}

.component-loader .anticon {
  font-size: 30px;
  top: 35%;
  position: relative;
}

/* Component Settings Form */
.post--component-form {
  padding: 0 10px;
  margin-bottom: 40px;
  position: relative;
}
.post--component-form--controls {
  margin-top: 30px;
}
.post--component-form-field {
  margin: 5px 0;
}
.post--component-form h3 {
  /* font-family: 'Poppins', sans-serif; */
  margin: 20px 0 10px 0;
  padding-bottom: 3px;
}
.post--component-form h4 {
  /* font-family: 'Poppins', sans-serif; */
  font-size: 14px;
  margin: 20px 0 10px 0;
  padding-bottom: 3px;
  border-bottom: 1px solid #ddd;
}
.post--component-form .ant-table-thead > tr > th,
.post--component-form .ant-table-tbody > tr > td {
  padding: 10px 2px !important;
  min-width: 20px !important;
  max-width: auto !important;
}
.btn-row-move {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
}
.text-center {
  text-align: center;
}
.cell-color {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.table-color-settings__type-input {
  margin-bottom: 10px;
}
.symbol-input,
.color-setting-row {
  margin: 3px 0;
}
.symbol-input--delete,
.color-setting-row__delete {
  position: absolute;
  top: 10px;
}
.symbol-input--preview {
  height: 100%;
  width: 100%;
  max-width: 25px;
  max-height: 25px;
}
.symbol-picker {
  padding-right: 16px;
  width: 240px;
  height: 250px;
  overflow-y: auto;
}
.symbol-settings--mode {
  margin: 20px 0;
}

.swatch {
  width: 30px;
  height: 8px;
  display: inline-block;
  margin: 0 5px 0 0;
}
label {
  display: block;
  margin: 8px 0 3px 0;
  color: #333;
  font-size: 12px;
  white-space: nowrap;
}
.picker div {
  box-shadow: none !important;
}

.post--component-drawer label {
  font-size: 11px;
}
.filter-input {
  margin: 5px 0;
}
.dropdown-input {
  padding: 3px 10px;
}
.color-scheme {
  margin: 0;
  position: relative;
  width: 100%;
}
.ant-select-selection-selected-value .color-scheme {
  position: absolute;
}
.swatch {
  display: inline-block;
  overflow: hidden;
  width: 30px;
  height: 8px;
  margin: 0;
}
.filters-mode,
.filters-container {
  margin: 10px 0;
}
.filter-item {
  border: 1px solid #ddd;
  padding: 10px;
  position: relative;
  margin: 10px 0;
}
.filter-item button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  z-index: 100;
}
.filter-item button .anticon {
  position: absolute;
  top: -4px;
}

.category-colors {
  margin: 10px 0;
}
.category-item {
  font-size: 11px;
  white-space: nowrap;
  margin-bottom: 5px;
}
.compact-color-picker div {
  box-shadow: none !important;
}
.compact-color-picker div div {
  background: none;
}

.component--filters {
  margin: 0 0 15px 0;
}
.filter-dropdown {
  background: #fff;
  width: 220px;
  padding: 20px;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.12);
}

.account-banner {
  padding: 80px;
  text-align: center;
  /* font-family: 'Poppins', sans-serif; */
}
.account-icon {
  width: 102px;
  height: 102px;
  border-radius: 15px;
  margin: 0 auto 20px auto;
  background-size: contain !important;
}
@media (max-width: 600px) {
  .account-icon {
    width: 72px;
    height: 72px;
  }
}
.account-banner h2 {
  /* font-family: 'Poppins', sans-serif; */
  font-size: 30px;
  color: #111;
}
.account-site {
  color: #111;
  font-weight: 700;
}
.account-site:hover {
  color: #333;
}
@media (max-width: 600px) {
  .account-banner {
    padding: 40px;
  }
}
.component--header-controls .filter-control {
  margin: 0 0 10px 0;
}

.drawer-list-item--name {
  display: block;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  white-space: nowrap;
  position: absolute;
  left: 23px;
  top: 11px;
}
.drawer-list-item--edit {
  position: absolute;
  right: 30px;
  top: 14px;
}
.drawer-list-item--more {
  position: absolute;
  right: 10px;
  top: 14px;
}

.post--component-layout.post--component-size--large {
  padding: 0px;
}
.post--component-map.post--component-size--large .component--caption {
  padding: 10px 0 0px 20px;
}
.post--component-image.post--component-size--large .component--caption {
  padding: 10px 0 0px 20px;
}

.layout--region .post--component-chart,
.card .post--component-card {
  padding: 0px !important;
}

.post--component-0 .post--component-chart.post--component-size--small,
.post--component-0 .post--component-chart.post--component-size--medium,
.post--component-0 .post--component-map.post--component-size--small,
.post--component-0 .post--component-map.post--component-size--medium,
.post--component-0 .post--component-image.post--component-size--small,
.post--component-0 .post--component-image.post--component-size--medium {
  margin-top: 20px;
}

@media (max-width: 600px) {
  body {
    padding-left: 0px;
  }
  .profile-menu {
    display: none;
  }
  .editor-ui .profile-menu {
    display: block;
  }
}

.post-template--map .post-template--map-instance .btn-width-options {
  display: none;
}

.post-template--map .post-template--map-instance .btn-export-options {
  display: none;
}

.post-template--map {
  background: #aaa !important;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
}

.post-template--map #nav-global {
  position: fixed;
  left: -9999px;
}

.post-template--map-layout .post--component .post--component-size--medium {
  width: 100%;
  max-width: 100%;
  padding: 0px;
  margin-bottom: 0px;
}

.post-template--map-layout {
  position: absolute;
  width: 500px;
  background: #fff;
}
.post-template--map-layout .layout--content {
  box-shadow: none !important;
}

.post-template--map-layout--collapsed-true {
  height: 45px !important;
}

@media (max-width: 600px) {
  #zoomIn,
  #zoomOut {
    display: none;
  }
}
.post-template--map-layout--visible-false-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 10;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 18px;
  color: #ccc;
}

.post-template--map-layout--visible-true-btn {
  position: absolute;
  right: 8px;
  font-size: 20px;
  top: 8px;
  z-index: 10;
  cursor: pointer;
  border: none;
  color: #ccc;
}

.post-template--map-layout--mobile-drag {
  display: none;
}
.post-template--map-layout--mobile-drag--element {
  width: 60px;
  height: 5px;
  background: #ddd;
  margin: 0 auto;
  cursor: pointer;
}
@media (max-width: 600px) {
  .post-template--map-layout--toggle-true .post-template--map-layout--mobile-drag {
    display: block;
  }
}

@media (max-width: 600px) {
  .post-template--map-layout--allow-close-true,
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-false {
    bottom: 0px !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    margin: 20% 2.5% 0 2.5% !important;
    width: 95% !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

@media (max-width: 600px) {
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-true {
    bottom: 0px !important;
    top: auto !important;
    right: auto !important;
    left: auto !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    margin: 2.5% 2.5% 0 2.5% !important;
    width: 95% !important;
    overflow-y: hidden !important;
  }
}
@media (max-width: 600px) {
  .post-template--map-layout--toggle-true .post-template--map-layout--mobile-drag {
    height: 10px;
    position: absolute;
    left: 0;
    top: 15px;
    width: 100%;
    z-index: 10;
    cursor: pointer;
  }
}

@media (max-width: 600px) {
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-false.post-template--map-layout--mobile-height-80 {
    height: 80% !important;
  }
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-false.post-template--map-layout--mobile-height-70 {
    height: 70% !important;
  }
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-false.post-template--map-layout--mobile-height-60 {
    height: 60% !important;
  }
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-false.post-template--map-layout--mobile-height-50 {
    height: 50% !important;
  }
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-false.post-template--map-layout--mobile-height-40 {
    height: 40% !important;
  }
  .post-template--map-layout--toggle-true.post-template--map-layout--collapsed-false.post-template--map-layout--mobile-height-30 {
    height: 30% !important;
  }
}

.post-template--map-layout .post--component-layout.post--component-size--large {
  padding: 0px;
}

@media (max-width: 600px) {
  .post-template--map-layout--hide-on-mobile-true {
    display: none !important;
  }
}

.post-template--map-layout--layout-title {
  position: absolute;
  left: 15px;
  top: 12px;
  font-weight: 700;
}

@media (max-width: 600px) {
  .post-template--map {
    left: 0px;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .user-auth .post-template--map {
    left: 0px;
    width: 100%;
  }
}
.post-template--map-layout--header {
  border-bottom: 1px solid #ddd;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  background: #fff;
}
.post-template--map-layout--header h3 {
  margin-bottom: 0px;
}

td .post--component-size--large,
td .post--component-size--medium,
td .post--component-size--small {
  padding: 0 !important;
}

td .post--component-table {
  min-height: auto;
  margin-bottom: 0;
}

td .region-empty {
  margin: 0;
}

.page-embed.user-auth .post-template--map {
  left: 0px !important;
  width: 100% !important;
}
.page-embed.show-edit-menu {
  padding-right: 0px !important;
}
.page-embed .btn-edit-component-menu {
  display: none !important;
}
.new-post-prompt {
  text-align: center;
  margin: 200px 0;
}