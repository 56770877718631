.post--component-drawer .content {
  padding: 15px;
}
.post--component-drawer label {
  text-transform: none;
  color: #777;
}
.post--component-drawer h3 {
  margin-top: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  text-transform: capitalize;
}
.post--component-drawer .ant-row {
  margin-bottom: 10px;
}

.layout-id {
  font-size: 11px;
  color: #777;
}
.layout--thumb {
  border: 1px solid #ddd;
  background: #fff;
  margin: 0 0 10px 0;
  padding: 5px 5px 0 5px;
  cursor: pointer;
  height: 35px;
}
.thumb-region {
  background: #ddd;
  height: 30px;
  margin-bottom: 5px;
}
.thumb-region-sm {
  background: #ddd;
  height: 22px;
  margin-bottom: 5px;
}
.thumb-region-md {
  background: #ddd;
  height: 20px;
  margin-bottom: 5px;
}
.thumb-region-xs {
  background: #ddd;
  height: 12px;
  margin-bottom: 5px;
}
.thumb-region-xxs {
  background: #ddd;
  height: 6px;
  margin-bottom: 5px;
}
.selected .layout--thumb {
  box-shadow: 0px 0px 6px rgb(7, 145, 236);
}

.source-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.source-list li a {
  display: block;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  background: #fff;
  color: #111;
  font-size: 12px;
}
.source-list li a:hover {
  opacity: 0.9;
}
.source-list li a:focus {
  border-left: 3px solid #222;
}

[data-type='dimension'] {
  border-radius: 4px;
  font-size: 13px;
  font-weight: 200;
  font-family: 'Courier', monospace;
}

[data-type='measure'] {
  border-radius: 4px;
  font-size: 13px;
  font-weight: 200;
  font-family: 'Courier', monospace;
}

[data-type='dimension'].ant-select-item-option-selected,
[data-type='measure'].ant-select-item-option-selected {
  background: #fff;
}
.editor-ui .ant-select-dropdown .ant-select-item {
  font-size: 12px;
}
.ui-icon {
  display: inline-block;
  width: 20px;
  line-height: 1.7;
  text-align: center;
  margin-right: 8px;
}
.ui-icon--dimension {
  color: #fff;
  background: #6baed6;
}
.ui-icon--measure {
  color: #fff;
  background: #f15a3e;
}
.clickhouse-source-type .ant-input-group-addon {
  padding: 0 0 !important;
}
.clickhouse-source-type .ant-input-group-addon .ant-select {
  margin: -5px 0 !important;
  background: unset !important;
}
