
.left-margin {
  padding-left: 70px;
}
@media (max-width: 600px) {
  .left-margin {
    padding-left: 0px !important;
  }
}
.profile-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 70px;
  border-right: 1px solid #eee;
  z-index: 100;
  background: #fff;
}

@media (max-width: 600px) {
  .profile-menu {
    display: none;
  }
}
.profile-menu--brand {
  display: block;
  text-indent: -9999px;
  width: 40px;
  height: 40px;
  margin: 18px auto 10px auto;
  background: url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center;
  background-size: contain;
}
.profile-menu--user {
  bottom: 18px;
  left: 15px;
  text-indent: -9999px;
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.profile-menu--user.no-image {
  bottom: 30px;
  left: 15px;
  position: absolute;
  text-indent: 0px;
  display: block;
  text-align: center;
  font-size: 20px;
  color: #111;
  background: #f9f9f9;
  border: 1px solid #ddd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.profile-menu--user.no-image .anticon {
  margin-top: 6px;
}

.profile-menu--links {
  position: absolute;
  top: 35%;
  width: 100%;
}
.profile-menu--link {
  text-align: center;
  width: 100%;
  font-size: 18px;
  line-height: 2.5;
  display: block;
  cursor: pointer;
  color: #111;
}
.profile-menu--divider {
  border-top: 1px solid #ddd;
  width: 40%;
  margin: 10px auto;
}

.profile-menu--link:hover {
  color: #444;
  cursor: pointer;
}
.profile-menu--sign-up {
  position: absolute;
  text-align: center;
  bottom: 25px;
  font-size: 26px;
  width: 40px;
  margin-right: auto;
  margin-left: auto;
  right: 0;
  left: 0;
  display: block;
  color: #555;
  cursor: pointer;
}

@media (max-height: 700px) {
  .profile-menu--link-sources {
    display: none;
  }
}
@media (max-height: 700px) {
  .profile-menu--link-posts {
    display: none;
  }
}
@media (max-height: 700px) {
  .profile-menu--link-posts {
    display: none;
  }
}
@media (max-height: 700px) {
  .profile-menu--link-new {
    display: none;
  }
}
@media (max-height: 700px) {
  .profile-menu--link-copy {
    display: none;
  }
}
