.style-settings label {
    text-transform: none;
    color: #777;
}
.style-settings h2 {
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.style-settings td {
    padding: 5px 0;
}