.post-settings-modal .ant-modal-content {
    padding: 15px;
    width: 600px;
}
.post-settings-modal label {
    text-transform: none;
}
.post-settings-modal .field {
    margin-bottom: 20px;
}
.post-settings-modal h2 {
    margin-top: 0px;
}