.space-header {
  text-align: right;
  margin-bottom: 10px;
}
.post a {
  font-size: 15px;
  color: #111;
  position: absolute;
  top: 85px;
  left: 15px;
  width: 80%;
}
.post .anticon {
  font-size: 30px;
}
.post {
  margin: 10px 0;
  padding: 15px;
  height: 250px;
  background: #f9f9f9;
  border: 1px solid #eee;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.post-image {
  background: #f2f2f2;
  border-bottom: 1px solid #ddd;
  height: 70px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.post-list {
  font-size: 16px;
  border-bottom: 1px solid #eee;
  padding: 10px 0 15px 0;
  position: relative;
}
.post-list .anticon {
  font-size: 30px;
  position: absolute;
  left: 0;
  top: 15px;
}
.post-list a {
  color: #111;
  display: block;
  margin: 8px 0 0 50px;
  font-size: 15px;
}
