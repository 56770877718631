.component-menu {
  color: #aaa;
  margin-top: 10px;
  padding-right: 5px;
  padding-left: 5px;
  text-overflow: ellipsis;
}
.component-menu .ant-tree .ant-tree-treenode {
  padding: 2px 0;
}
.component-menu a {
  color: #111;
}
.dark-mode .component-menu a {
  color: #aaa;
}
.component-menu .tree-node-item {
  max-width: 85%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.component-menu .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
  display: none;
}
.dark-mode.editor-ui .ant-tree {
  background: none;
}
.component-menu h2 {
  font-size: 13px;
  margin: 0 0 10px 10px;
  color: #111;
  font-weight: 600;
}
.dark-mode .component-menu h2 {
  color: #aaa;
}
.dark-mode.editor-ui .ant-tree {
  color: #aaa;
}
.editor-ui .ant-tree {
  background: none !important;
}
.editor-ui .ant-tree-title {
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  font-size: 13px;
}
.dark-mode.editor-ui .ant-tree-node-content-wrapper:hover {
  background: #383f49;
  color: #ccc;
}
.dark-mode.editor-ui .ant-tree-treenode:hover {
  color: #ccc;
}
.dark-mode.editor-ui .ant-tree-treenode:hover {
  background: #383f49;
}
.dark-mode.editor-ui .ant-tree-node-selected {
  background: #383f49;
  color: #ccc;
}
.dark-mode.editor-ui .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected  {
  background: #383f49;
}


.editor-ui .ant-tree-node-content-wrapper {
  padding: 0px !important;
}
.menu-expanded.editor-ui .ant-tree-title { 
  max-width: 240px;
  text-overflow: ellipsis;
  width: auto;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  border: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: none;
}
.dark-menu .component-menu .btn-add {
  color: #aaa;
}
.component-menu .btn-add {
  margin-top: -10px;
  margin-left: 18px;
  margin-bottom: 0px;
}
.component-menu .btn-add .anticon {
  font-size: 12px !important
}

.treenode-options-menu {
  width: 130px;
  background: #fff;
  padding: 3px;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0 1px 7px rgb(0 0 0 / 20%);
}
.treenode-options-menu ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.treenode-options-menu ul li {
  display: block;
}
.treenode-options-menu ul li:hover {
  background: #f9f9f9;
}
.treenode-options-menu ul li a {
  display: block;
  padding: 5px 20px 5px 10px;
  font-size: 13px;
}
.editor-left-nav-toggle  {
  position: fixed;
  left: 210px;
  top: 62px;
  font-size: 12px !important;
  color: #111 !important;
  z-index: 100;
}