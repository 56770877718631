.post--component-chart {
  margin: 0 0 10px 0;
}
.post--component-chart .layer-selector {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1000;
  background: #fff;
}
.post--component-chart .chart {
  position: relative;
}

.ant-col-sm-12 .post--component-chart.post--component-size--small {
  max-width: 100% !important;
  padding: 0 20px;
}
.ant-col-sm-12 .post--component-chart.post--component-size--medium {
  max-width: 100% !important;
  padding: 0 20px;
}
.ant-col-sm-12 .post--component-chart.post--component-size--large {
  max-width: 100% !important;
}

.ant-col-sm-8 .post--component-chart.post--component-size--small {
  max-width: 100% !important;
  padding: 0 20px !important;
}
.ant-col-sm-8 .post--component-chart.post--component-size--medium {
  max-width: 100% !important;
  padding: 0 10px !important;
}
.ant-col-sm-8 .post--component-chart.post--component-size--large {
  max-width: 100% !important;
}

.ant-col-sm-6 .post--component-chart.post--component-size--small {
  max-width: 100% !important;
  padding: 0 20px !important;
}
.ant-col-sm-6 .post--component-chart.post--component-size--medium {
  max-width: 100% !important;
  padding: 0 10px !important;
}
.ant-col-sm-6 .post--component-chart.post--component-size--large {
  max-width: 100% !important;
}
.series-item {
  position: relative;
}
.series-item-header {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  position: relative;
}
.series-item-header .series-name {
  display: block;
  margin: 0 0 0 10px;
  max-width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.series-item-content {
  padding: 10px;
}
.dark-mode .series-item-header {
  border-bottom: 1px solid #555;
}
.series-item-settings {
  position: absolute;
  right: 30px;
  top: 10px;
}
.series-item-menu {
  position: absolute;
  right: 10px;
  top: 10px;
}
.series-item .btn-delete {
  position: absolute;
  display: block;
  right: 10px;
  left: auto;
  cursor: pointer !important;
  z-index: 1000;
  color: #777 !important;
  top: 10px;
}
.post--component-drawer .ant-dropdown-menu-item {
  padding: 5px 10px;
}
.post--component-drawer ..ant-dropdown-menu-item > .anticon:first-child {
  margin-right: 0px;
}
.dark-mode .series-item .btn-delete {
  color: #ccc !important;
}
.group-color-item {
  margin: 5px 0 5px 0;
  width: 100%;
}
#component-tabs {
  position: relative;
}
.g2-tooltip {
  box-shadow: none !important;
  border: 1px solid rgba(0,0,0,0.1);
  color: #000 !important;
  font-size: 13px;
}