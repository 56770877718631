.source-menu {
    padding: 20px 20px;
}
.source-menu h2 {
    font-size: 14px;
    margin-left: 15px;
}
.dark-mode .source-menu h2 {
    color: #aaa;
}
.dark-mode .source-menu a {
    color: #aaa;
}

.source-menu-search {
    margin-bottom: 10px;
    max-width: 250px;
}
.dark-mode .source-menu-search {
    background: #1B1D22;
    border: none;
}
.source-menu-search .anticon {
    color: #555;
}
.source-menu-search input {
    max-width: 250px;
    border: none;
}
.dark-mode .source-menu-search input {
    background: #1B1D22;
    color: #fff;
}