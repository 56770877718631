.input--embed-code {
  font-family: 'Courier', monospace;
  font-size: 12px;
  color: #777;
  background: #f2f2f2;
}
.modal--embed .ant-modal-title {
  font-weight: 700;
}
.modal--embed .embed-preview {
  border: none;
}
.embed--component-item {
  margin: 15px 0;
}
.embed-link {
  font-size: 12px;
  color: #777;
}