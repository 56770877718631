.post--component-number {
  margin-bottom: 10px;
}
.post--component-number .component-empty {
  font-size: 30px;
  height: 80px;
}
.layout--region .post--component-number .component-empty {
  margin-bottom: 10px;
}
.component--number {
  font-weight: 700;
  font-size: 40px;
  color: #777;
  background: rgba(0, 0, 0, 0.02);
  text-align: center;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.icon-component {
  padding: 20px 0;
  text-align: center;
}
.icon-wrapper {
  margin: 0 auto;
  position: relative;
}
.icon-wrapper .ant-image {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.component--number-value {
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.component--number-default {
  color: #ddd;
}
.post--component-number.post--component-size--large,
.post--component-number.post--component-size--medium,
.post--component-number.post--component-size--small {
  padding: 0px 40px;
}
