
.user-icon {
    display: block;
    width: 28px;
    height: 28px;
    color: #fff;
    background: rgb(237, 134, 8);
    border-radius: 100%;
    line-height: 2.8;
    cursor: pointer;
  }
  .user-icon-name {
    display: block;
    position: absolute;
    width: 28px;
    text-align: center;
    top: -5px;
    font-size: 14px;
  }
  .dropdown-content {
    background: #fff;
  }
  .user-dropdown .ant-dropdown-menu {
    min-width: 210px;
  }