.ant-color-picker .ant-color-picker-inner-content {
  width: 200px !important;
}

.ant-color-picker .ant-collapse-header {
  border: none;
}

.ant-color-picker .ant-collapse-header .ant-collapse-expand-icon {
  display: none !important;
}
.ant-color-picker .ant-color-picker-inner-content .ant-color-picker-presets-label {
  padding-bottom: 2px;
  font-weight: normal;
  text-transform: capitalize;
}
.inherit-color-swatch {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  border-radius: 50%;
}