
.post--component-title {
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (max-width: 700px) {
  .post--component-title { 
    margin-top: 40px;
  }
}
.post--component-title {
  line-height: 1.4;
}
.post--component-title h1 {
  font-size: 36px;
  margin-bottom: 0px;
  color: inherit;
}
@media (max-width: 700px) {
  .post--component-title h1 { 
    font-size: 38px !important
  }
}
.description {
  margin: 0px 0 5px 0;
  font-size: 20px;
  line-height: 1.6;
  font-family: inherit;
}
.description p {
  margin: 0 0 0 0;
}
@media (max-width: 600px) {
  .description {
    font-size: 16px;
  }
}