.color-range-input-panel {
  max-width: 237px;
  position: relative;
}
.color-range-input-panel-close {
  position: absolute;
  right: 0px;
  top: 0px;
}
.color-range-input {
  border: 1px solid #ddd;
  line-height: 0;
  margin: 5px 0;
}
.item-color-range-input {
  width: 11%;
  height: 18px;
  background: #f9f9f9;
  display: inline-block;
  cursor: pointer;
}
.item-color-value {
  position: relative;
}
.item-color-value .item-color-input {
  position: absolute;
  top: 5px;
  left: 0px;
}