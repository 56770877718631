.symbol-input {
  border: 1px solid #ddd;
  padding: 10px;
  position: relative;
  margin: 10px 0;
}

.symbol-input .anticon-delete {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 12px;
  z-index: 100;
  position: absolute;
}
