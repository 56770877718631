.source-form {
  width: 100%;
}
.source-form .ant-collapse-content .ant-collapse-content-box {
  padding: 10px 10px !important;
}
.ant-form-item {
  margin-bottom: 10px;
}
.source-form label {
  color: #777;
}
.source-form .ant-collapse-header {
  background: #fff;
  font-weight: 700;
}
.source-form .ant-collapse {
  border-bottom: 1px solid #ddd;
}
.source-form .ant-collapse-content {
  background: #f9f9f9;
  overflow: hidden;
}
.source-form .ant-collapse .ant-collapse .ant-collapse-header {
  border: none;
  border-radius: 4px;
}
.ant-collapse p {
  font-size: 11px;
}
.source-form .ant-collapse .ant-collapse .ant-collapse-header {
  padding: 8px 12px;
  font-size: 11px;
  color: #777;
}
.ant-collapse .ant-collapse {
  margin-bottom: 5px;
}
.progress {
  margin: 20px 0;
  height: 33px;
  width: 100%;
  position: relative;
  border: 1px solid #ddd;
  background: #f9f9f9;
}
.progress-bar {
  height: 31px;
  background: #ddd;
}
.processing-msg {
  text-align: center;
  margin-top: 5px;
}
.ant-select {
  width: 100%;
}
.input-field {
  margin: 5px 0 5px 0;
}
.source-query {
  margin: 10px 0;
  overflow: hidden;
}
.measure-sql {
  font-family: Courier, monospace;
}
.collapse-header-label {
  font-size: 13px;
  color: #444;
  font-weight: 200;
}
.btn--item-delete {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}
.success {
  color: green;
}
.btn-field {
  margin-top: 10px;
}
.source-connection {
  margin-top: 20px;
}
.source-refresh {
  margin-top: 20px;
}
.ant-form-item-label {
  padding: 0px !important;
}
.ant-form-item-label label {
  font-size: 11px !important;
  color: #111;
}
.postgres-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  background: url('https://assets.akuko.io/postgres.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.clickhouse-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  background: url('https://assets.akuko.io/clickhouse.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.mysql-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  background: url('https://assets.akuko.io/mysql.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.sheet-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  margin-top: 2px;
  background: url('https://assets.akuko.io/sheet.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.onadata-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  margin-top: 2px;
  background: url('https://assets.akuko.io/4c357640-227a-11ed-a197-25ff00f13e06.png') no-repeat
    center bottom;
  background-size: contain;
  display: inline-block;
}
.csv-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  background: url('https://assets.akuko.io/csv.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.parquet-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  background: url('https://assets.akuko.io/parquet.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.geoparquet-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  background: url('https://assets.akuko.io/parquet.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.geojson-icon {
  position: relative;
  bottom: -2px;
  width: 15px;
  height: 15px;
  background: url('https://assets.akuko.io/geojson.png') no-repeat center bottom;
  background-size: contain;
  display: inline-block;
}
.geojson-field {
  padding: 15px;
  background: #f9f9f9;
  margin: 0 0 10px 0;
}
.geojson-field p {
  margin-top: 10px;
}
.pmtiles-geometry-input {
  margin-bottom: 15px;
}
.advanced-settings {
  margin: 10px 0 15px 0l;
}
.cube-input {
  color: #555;
  font-size: 13px;
  font-family: Courier, monospace;
}

.dimension-panel .ant-collapse-header {
  font-family: Courier, monospace;
}
.measure-panel .ant-collapse-header {
  font-family: Courier, monospace;
}

.ui-icon {
  display: inline-block;
  width: 20px;
  line-height: 1.7;
  text-align: center;
  margin-right: 8px;
}
.ui-icon--dimension {
  color: #fff;
  background: #6baed6;
}
.ui-icon--measure {
  color: #fff;
  background: #f15a3e;
}

.query-results-table td {
  font-size: 12px;
}

.geometry-select-modal {
  width: 70% !important;
  max-width: 900px;
}
.geometry-item {
  padding: 15px;
  border: 1px solid #ddd;
  margin: 0 0 10px 0;
  position: relative;
  min-height: 150px;
}
.geometry-item h2 {
  white-space: nowrap;
  max-width: 70%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 0 0 20px;
}
.geometry-link {
  position: absolute;
  left: 15px;
  top: 15px;
}
.geometry-thumb {
  right: 15px;
  top: 15px;
  position: absolute;
  height: 120px;
  width: 120px;
  background: #f9f9f9;
  border: 1px solid #ddd;
}
.geometry-thumb .anticon {
  color: #ccc;
  font-size: 30px;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 40px;
}
.geometry-select-modal .ant-input-search {
  margin-bottom: 10px;
}
.geometry-item .ant-btn {
  position: absolute;
  left: 15px;
  bottom: 15px;
}
.ant-collapse {
  border-radius: 0;
}
