
.profile-header {
  height: 300px;
  position: relative;
  background: #272D31 url(https://assets.akuko.io/akuko-callout-bg.jpg);
}
.profile-header--brand {
  position: absolute;
  top: 30px;
  left: 20px;
  height: 40px;
  width: 40px;
  text-indent: -9999px;
  filter:invert();
  background: url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center;
  background-size: contain;
  z-index: 30;
  display: none;
}
@media(max-width: 600px) {
  .profile-header--brand {
    display: block;
  }
}
.profile-header h1 {
  position: absolute;
  left: 40px;
  bottom: 0px;
  color: #fff;
  font-size: 40px;
  text-shadow: 1px 1px 1px #000;
  z-index: 30;
}
@media (max-width: 600px) {
  .profile-header h1 {
    left: 20px;
  }
}
.profile-header::after {
  content: '.';
  position: absolute;
  width: 100%;
  height: 300px;
  background: rgba(39,45,49,0.9);
  top: 0;
  left: 0;
  z-index: 20;
}
.profile-posts--header h1 {
  font-size: 40px;
  margin-bottom: 0px;
}
.profile-posts--header p {
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  color: #555;
}
.profile-posts--header {
  margin-bottom: 40px;
}
.profile-posts {
  margin: 40px 0;
  padding: 0 40px 0 40px;
}
@media (max-width: 600px) {
  .profile-posts {
    padding: 0 20px;
  }
}
.profile-posts a h2 {
  color: #000;
}
.profile-posts p {
  font-family: 'PT Serif', serif;
  font-size: 18px;
  margin-bottom: 0px;
}
.profile-post {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.profile-post:last-child {
  border-bottom: none;
}
.profile-post .content {
  min-height: 140px;
}
.profile-post--thumb {
  width: 120px;
  height: 120px;
  float: right;
  background-size: cover;
  margin: 0 0 20px 20px;
}
.profile-post--date {
  margin-bottom: 15px;
  color: #777;
  font-size: 11px;
}
.profile-post--thumb.no-thumb {
  background-size: 50% !important;
  opacity: 0.1;
}
.profile-post--space-account {
  display: block;
  width: 24px;
  height: 24px;
  text-indent: -9999px;
  background-size: contain;
  position: absolute;
  left: 0;
  top: -3px;
}
.profile-post--space-name {
  text-transform: capitalize;
}
.profile-post--space {
  margin-bottom: 15px;
  color: #777;
}
.profile-post--space small {
  margin-left: 30px;
}



.profile--sidebar {
  position: relative;
}
.profile--sidebar .content {
  padding: 40px;
  height: 100vh;
  border-left: 1px solid #ddd;
  position: fixed;
  width: 320px;
}
@media (max-width: 990px) {
  .profile--sidebar .content {
    position: relative;
    border: none;
    min-height: 350px;
    height: auto;
    width: auto;
  }
  .profile--sidebar .global-footer {
    margin-top: 40px !important;
  }
}
@media (max-width: 600px) {
  .profile--sidebar .content {
    padding: 20px;
  }
  .profile--sidebar .global-footer {
    left: 0px !important;
  }
}

.profile-sidebar--search {
  margin: 20px 0;
}
.profile-sidebar--sign-in {
  margin-bottom: 40px;
}
.profile-sidebar--profile-pic {
  width: 90px;
  height: 90px;
  margin: 20px 0;
  background: #f2f2f2;
  border: 1px solid #ddd;
  border-radius: 50%;
  text-align: center;
  position: relative;
}
.profile-sidebar--profile-pic .anticon {
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  font-size: 30px;
  margin-right: auto;
  margin-left: auto;
  color: #ccc;
}
.profile-sidebar--name {
  font-weight: bold;
}
.profile-sidebar--handle {
  color: #777;
  margin-bottom: 10px;
}
.profile-sidebar--description {
  margin-top: 10px;
}