
  .source-item {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    height: 160px;
    background: #f9f9f9;
  }
  @media (max-width: 1000px) {
    .source-item {
      height: 200px;
    }
  }
  .source-item.active {
    background: #f1f9ff;
    border-left: 5px solid #1890ff;
  }
  .source-item h2 {
    white-space: nowrap;
    max-width: 70%;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 0 0 20px;
  }
  .source-item .ant-btn {
    position: absolute;
    left: 15px;
    bottom: 15px;
  }
  .source-id {
    color: #777;
    font-size: 12px;
  }
  .source-meta {
    margin-bottom: 25px;
    font-size: 12px;
    color: #333;
  }
  .source-thumb {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 15px;
    top: 15px;
    text-align: center;
  }
  .source-thumb .anticon {
    margin-top: 5px;
    color: #ccc;
    font-size: 30px;
  }
  .source-list:empty {
    text-align: center;
    color: #777;
    font-size: 16px;
    padding: 20px 0;
  }
  .source-list:empty::after {
    content: 'No sources found.';
  }
  .source-list-search {
    margin-bottom: 10px;
  }
  .source-link {
    left: 15px;
    top: 15px;
    position: absolute;
    display: block;
  }
  .ant-checkbox-wrapper label {
    display: inline;
  }
  .field-input--item .ant-col .ant-checkbox-wrapper label {
    display: inline;
  }
  
  .settings-group--collapse .ant-collapse-header {
    background: #fff;
    font-weight: bold;
    border-bottom: 1px solid #ddd;
  }
  .source-select-modal .ant-input {
    font-size: 14px !important;
  }
  .setting-inherited {
    display: inline-block;
    width: 4px;
    height: 25px;
    border-top: 4px solid orange;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: #fff; 
    position: absolute;
    left: -10px;
    top: 2px;
    cursor: pointer;
  }
  .inherit-info .ant-popover-inner-content {
    font-size: 11px;
  }