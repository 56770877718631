.global-footer {
  position: absolute;
  text-align: right;
}
.global-footer a {
  display: inline-block;
  margin: 0 0 0 15px;
  color: #999;
  font-size: 12px;
}
