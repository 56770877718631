
.post--component-image {
  margin-bottom: 10px;
}
.post--component-image--image {
  height: 500px;
  background-size: cover;
  background-position: center;
}
.post--component-0 .post--component-image.post--component-size--small,
.post--component-0 .post--component-image.post--component-size--medium {
  margin-top: 60px;
}
.post--component-image .component--footer {
  margin-bottom: 10px;
}
.component--caption {
  color: #777 !important;
}
.post--component-image img {
  width: 100%;
}
.layout--region img {
  max-width: 100% !important;
}
@media(max-width: 1200px) {
  .post--component-image.post--component-size--medium .component--caption {
    padding-left: 10px;
  }
}
