.form-set-password {
  padding: 0 40px;
  margin: 0 auto 100px auto;
  right: 0;
  left: 0;
  max-width: 400px;
  position: absolute;
  top: 50%;
  transform: translateY(-60%);
}
@media (max-width: 800px) {
  .form-set-password {
    position: relative;
    transform: none;
    top: 0;
    margin-top: 50px;
    margin-bottom: 100px;
  }
}
.form-set-password  h1 {
  text-align: center;
}
.form-set-password  {
  font-size: 17px;
  text-align: center;
}
.form-set-password--brand {
  max-width: 260px;
  display: block;
  margin: 0 auto 20px auto;
}
.form-set-password--legal {
  color: #777;
  font-size: 11px;
}
.form-set-password--terms {
  text-transform: none;
  font-size: 11px;
  color: #555;
}
.form-set-password button {
  display: block;
  width: 100%;
}