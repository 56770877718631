.nav {
  width: 100%;
  z-index: 1000;
  position: relative;
}
.nav .content {
  max-width: 100%;
  padding-left: 20px;
  margin: 0 auto;
}
.nav .menu-cntrl {
  right: 25px;
  position: absolute;
  top: 35px;
  font-size: 24px;
  color: #111;
  cursor: pointer;
}
.user-menu-cntrl {
  font-size: 30px;
  width: 30px;
  line-height: 2;
  color: #111;
  cursor: pointer;
  text-align: center;
  position: absolute;
  right: 40px;
  top: 25px;
}
.dark-mode .user-menu-cntrl {
  color: #eee;
}
.btn-logout,
.btn-login,
.btn-post {
  cursor: pointer;
  border: none;
  width: 100%;
  display: block;
  text-align: left;
  background: none !important;
}
.btn-account {
  cursor: pointer;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
}
.user-menu li,
.sign-in-menu li,
.account-menu li {
  padding: 0;
  width: 200px;
}
.user-menu a,
.sign-in-menu a,
.account-menu a {
  display: block !important;
  line-height: 2;
  padding: 8px 20px;
  font-size: 13px;
  margin: 0 !important;
}
.user-menu button,
.sign-in-menu button,
.account-menu button {
  padding: 8px 20px;
  font-size: 13px;
}
.menu-item-user {
  width: 220px;
  padding: 20px;
  font-size: 12px;
}
.menu-item-user .profile-pic {
  width: 30px;
  height: 30px;
  background: #eee;
  border-radius: 50%;
}
.nav .account-brand {
  padding: 30px 0;
  margin: 0 0 0 15px;
  display: block;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 700;
  color: #111;
}

@media (max-width: 600px) {
  .nav .account-brand {
    top: 42px;
    font-size: 16px;
  }
}
.nav .account-name {
  letter-spacing: 0px;
  margin-left: 10px;
}
@media (max-width: 600px) {
  .nav .account-name {
    margin-left: 5px;
  }
}
.dark-mode .account-name {
  color: #fff;
}
.dark-mode .auth-logo {
  color: #fff;
}
.nav-lgt .account-name {
  color: #eee;
}
.nav-lgt .menu-cntrl {
  color: #eee;
}
.nav-lgt .user-menu-cntrl {
  color: #eee;
}
.space-logo {
  margin: 15px 0 15px 0;
  margin-left: 20px;
  animation: fadeIn 500ms;
}
@media (max-width: 600px) {
  .space-logo {
    max-width: 320px;
    margin-left: 15px;
  }
}
.space-menu {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px 25px 10px 5px;
}
@media (max-width: 600px) {
  .space-menu {
    padding: 0;
  }
}
.space-menu .ant-menu {
  background: transparent;
  border-bottom: 0;
}
.menu-item-user a {
  padding: 0;
  color: #555;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.space-menu .ant-menu-item:hover {
  opacity: 0.8 !important;
}
.space-menu .ant-menu-item:hover::after {
  display: none;
}
.space-menu .ant-menu-item-selected::after {
  border-color: #fff !important;
  display: none;
}
.space-menu .content {
  max-width: 100%;
  margin: 0 auto;
}
.nav--profile-header {
  height: 0px;
  background: #f9f9f9;
  overflow: hidden;
  position: relative;
}
.nav--profile-header--brand {
  display: block;
  text-indent: -9999px;
  width: 40px;
  height: 40px;
  margin: 30px auto 10px auto;
  background: url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center;
  background-size: contain;
  left: 20px;
  position: absolute;
}
@media (max-width: 600px) {
  .nav--profile-header {
    height: 100px;
    margin-bottom: 0px;
  }
}