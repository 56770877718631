/* .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
} */

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px !important;
}
.ant-collapse-content-box .region-empty {
  margin-bottom: 0px;
}

.post--component-layout {
  margin-bottom: 10px;
}
.layout--region .post--component-layout {
  margin-bottom: 0px;
}
.layout--region .layout--region .post--component-layout {
  margin-bottom: 0px !important;
}
.layout--region .post--component-layout .ant-row:last-child .layout--region {
  margin-bottom: 0px !important;
}
.post--component-layout .ant-row:last-child .region-empty {
  margin-bottom: 0px !important;
}
.post--component-layout .ant-row:last-child .post--component-chart,
.post--component-layout .ant-row:last-child .post--component-card,
.post--component-layout .ant-row:last-child .post--component-image,
.post--component-layout .ant-row:last-child .post--component-number,
.post--component-layout .ant-row:last-child .post--component-filter,
.post--component-layout .ant-row:last-child .post--component-table {
  margin-bottom: 0px !important;
}
.post--component-layout .post--component-layout {
  margin-bottom: 0px;
}

@media (max-width: 600px) {
  .post--component-text,
  .post--component-navLayout,
  .post--component-layout,
  .post--component-title,
  .post--component-chart,
  .post--component-map,
  .post--component-table,
  .post--component-card {
    padding: 0 20px !important;
  }
}

.post--component-navLayout.post--component-size--large {
  padding: 0px !important;
  margin: 0 auto;
}
.post--component-layout.post--component-size--large {
  margin: 0 auto;
  padding: 0px !important;
}
.post--component-map.post--component-size--large {
  padding: 0px !important;
}

.post--component-navLayout .post--component-text,
.post--component-navLayout .post--component-text,
.post--component-navLayout .post--component-title,
.post--component-navLayout .post--component-chart,
.post--component-navLayout .post--component-button,
.post--component-navLayout .post--component-number,
.post--component-navLayout .post--component-table,
.post--component-navLayout .post--component-card {
  padding: 0px !important;
}

.post--component-layout .post--component-title,
.post--component-layout .post--component-chart,
.post--component-layout .post--component-button,
.post--component-layout .post--component-number,
.post--component-layout .post--component-table,
.post--component-layout .post--component-card {
  padding: 0px !important;
}
.post--component-card .post--component-text,
.post--component-card .post--component-text,
.post--component-card .post--component-title,
.post--component-card .post--component-chart,
.post--component-card .post--component-button,
.post--component-card .post--component-number,
.post--component-card .post--component-table,
.post--component-card .post--component-card {
  padding: 0px !important;
}
.ant-tabs-tab {
  font-size: inherit;
}

.layout--region {
  position: relative;
}
.layout--region .btn-add {
  margin-bottom: 0;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  width: 30px;
  margin-right: auto !important;
  margin-left: auto !important;
  top: 14%;
  background: none;
  z-index: 100;
}
.layout--region .post--component-figure {
  padding: 0;
}

.templates-list {
  margin-top: 20px;
}

.layout--region .btn-width-options {
  display: none !important;
}
/*
.layout--region .post--component-edit {
  position: absolute;
  z-index: 100;
  top: 0px;
}
*/

.layout--region .post--component-size--large .component--title {
  padding-left: 0 !important;
}
.layout--region .post--component-size--large {
  padding: 0 !important;
}
.layout--region-content {
  min-height: 100px;
  max-height: 700px;
  overflow: hidden;
  overflow-y: scroll;
  position: relative;
}
.layout--region-content .card .post--component-edit {
  top: 0px;
  left: 0px;
}
.region-empty {
  width: 100%;
  height: 100%;
  min-height: 45px;
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.02);
}

.card .post--component-size--medium {
  max-width: 100%;
  min-width: 100%;
}

.card .post--component-size--medium {
  max-width: 100%;
  min-width: 100%;
}

.component--handle {
  position: absolute;
  left: 78px;
  top: 12px;
  color: #555;
  z-index: 200;
}
.layout--region .component--handle {
  position: absolute;
  left: 50px;
  top: 4px;
  color: #555;
  z-index: 200;
}
.component--handle:hover {
  cursor: pointer;
}

body.move-component .region-empty {
  border: 1px solid red;
  opacity: 1;
}
body.move-component .region-empty:hover {
  cursor: pointer;
}

.layout-settings--tabs {
  margin-bottom: 100px;
}
.layout-settings--tab {
  padding: 15px;
  border: 1px solid #ddd;
  margin: 10px 0;
  position: relative;
}
.tab-delete {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #555;
}

.layout--region .component--caption {
  padding-left: 0px !important;
}

.card .post--component-edit {
  left: -40px;
}
.card .layout--region .card .post--component-edit {
  left: 0px;
}
.card .layout--region .post--component-card {
  margin-bottom: 0px !important;
}

.layout--region .post--component-text .post--component-content {
  max-width: 100%;
}

.layout--region .layout--region {
  margin-bottom: 10px;
}
.layout--region .layout--region .layout--region {
  margin-bottom: 5px;
}

.layout--region .component--title {
  font-size: 15px;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;
}

.layout--region .post--component-image,
.layout--region .post--component-image img {
  width: 100%;
}

.ant-tabs {
  color: inherit;
  overflow: visible !important;
}

.ant-tabs-left .ant-tabs-tab .ant-tabs-tab-btn {
  text-align: right !important;
}

.layout--region .post--component-chart.post-component-size--small {
  padding: 0px !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.drag-handle {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: move;
  z-index: 100;
  border: 1px solid #ddd;
  outline: none;
}
.layout--region a#btn-add-component {
  color: #999;
}

.layout--region .post--component-map {
  margin-bottom: 0px !important;
}
