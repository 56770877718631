.post--component-button .component-empty  {
  font-size: 30px;
  height: 80px;
}
.post--component-button .ant-checkbox-wrapper,
.post--component-button .ant-checkbox-wrapper span {
  text-transform: none;
}
.post--component-button.post--component-size--large,
.post--component-button.post--component-size--medium,
.post--component-button.post--component-size--small {
  padding: 0px;
}
.post--component-button .ant-menu {
  background: none !important;
  border-bottom: none !important;
}
.post--component-button label {
  text-transform: none;
}
.ant-checkbox-checked::after {
  display: none;
}
.post--component-button button {
  margin: 0 10px 10px 0;
}
.menu-divider {
  display: block;
  color: #777;
  padding: 0 0 0 15px;
  line-height: 3;
  font-weight: bold;
}
.ant-menu-submenu-vertical .ant-menu-title-content {
 position: static;
 top: auto;
 transform: none; 
}
.ant-menu-overflow-item,
.ant-menu-item {
  display: flex !important;
  align-items: center !important;
}