.post--component-html .post--component-content {
  font-size: 19px;
}
.post--component-html p,
.post--component-html .post--component-content li {
  line-height: 1.7;
  font-size: inherit;
  margin-top: 0px;
}
.post--component-html .post--component-content p small {
  margin: 10px 0;
}
small {
  display: block;
  line-height: 1.4 !important;
  font-size: 13px;
}
.rc-md-editor {
  height: 245px !important;
  border: 1px solid #ddd;
  background: #fff !important;
  padding: 0px 0px 0px 20px;
}
.post--component-drawer .rc-md-editor {
  min-height: 200px !important;
  background: transparent !important;
}
.rc-md-editor .editor-container .sec-md .input {
  font-family: courier;
  background: transparent !important;
  line-height: 1.7;
  padding: 0 !important;
  font-size: 17px !important;
}
@media (max-width: 600px) {
  .rc-md-editor .editor-container .sec-md .input {
    padding: 0 10px 10px 10px !important;
  }
}
.layout--region .rc-md-editor .editor-container .sec-md .input {
  font-size: 14px !important;
}

.post--component-drawer .rc-md-editor .editor-container .sec-md .input {
  font-family: courier !important;
  line-height: 1.5;
  padding: 10px 10px 10px 10px !important;
  font-size: 18px !important;
}
.rc-md-editor .editor-container .sec-md .tool-bar {
  display: none !important;
}

@media (max-width: 600px) {
  .post--component-html {
    font-size: 17px;
  }
}
.post--component-html .post--component-content a {
  text-decoration: underline;
}

.post--component-html code {
  background: #F2F6C6;
   /* font-family: 'Poppins', sans-serif; */
}

.post--component-html .post--component-content blockquote {
 /* font-family: 'PT Serif', serif; */
  font-size: 12px;
  line-height: 1.6;
  padding: 0 0 0 40px;
  margin: 40px 40px 40px 0px;
  border-left: 2px solid #ddd;
}
@media (max-width: 600px) {
  .post--component-html .post--component-content blockquote {
    margin: 20px 20px 20px 0px;
    padding: 0 0 0 20px;
  }
}
.post--component-html .post--component-content blockquote h1,
.post--component-html .post--component-content blockquote h2,
.post--component-html .post--component-content blockquote h3,
.post--component-html .post--component-content blockquote h4,
.post--component-html .post--component-content blockquote h5 {
  /* font-family: 'PT Serif', serif; */
  font-size: 30px;
}
@media (max-width: 600px) {
  .post--component-html .post--component-content blockquote h1,
  .post--component-html .post--component-content blockquote h2,
  .post--component-html .post--component-content blockquote h3,
  .post--component-html .post--component-content blockquote h4,
  .post--component-html .post--component-content blockquote h5 {
    /* font-family: 'PT Serif', serif; */
    font-size: 24px;
  }
}

.card .post--component-html .post--component-content h1,
.card .post--component-html .post--component-content h2,
.card .post--component-html .post--component-content h3,
.card .post--component-html .post--component-content h4,
.card .post--component-html .post--component-content h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card .post--component-figure,
.card .post--component-chart {
  padding: 0 !important;
}

@media (max-width: 600px) {
  .post--component-html .post--component-content h1 {
    font-size: 20px;
  }
  .post--component-html .post--component-content h2 {
    font-size: 18px;
  }
  .post--component-html .post--component-content h3 {
    font-size: 16px;
  }
  .post--component-html .post--component-content h4 {
    font-size: 14px;
  }
  .post--component-html .post--component-content h5 {
    font-size: 14px;
  }
}

.layout--region .post--component-html .post--component-content h1,
.layout--region .post--component-html .post--component-content h3,
.layout--region .post--component-html .post--component-content h2 {
  width: 95%;
  white-space: normal;
  overflow: hidden;
  text-overflow: elipsis;
  margin: 5px 0;
}
.layout--region .post--component-html .post--component-content p {
  max-width: 95%;
  font-family: inherit;
}
.layout--region .rc-md-editor {
  max-width: 100% !important;
  min-height: 200px !important;
  height: 200px !important;
  margin: 0 auto;
  border: none !important;
}

.post--component-html--editor {
  position: relative;
}
.btn-close-txt {
  position: absolute;
  top: 0px;
  right: 0px;
}
.btn-save-txt {
  position: absolute;
  top: 0;
  right: 35px;
}