.symbol-picker--wrapper {
  margin: 3.2px;
  padding: 3px;
  border: 1px solid #eaebec;
  text-align: center;
}

.symbol-picker--icon {
  max-width: 32px;
  max-height: 32px;
}
.symbol-picker--icon:hover {
  cursor: pointer;
}
.symbol-picker--loader {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin-top: -9px;
}
.symbol-picker--search {
  margin-bottom: 5px;
  display: block;
}
.symbol-picker--color-picker {
  display: block;
  margin: 10px 0;
}
.symbol-picker--color-option {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 10%);
  color: transparent;
}
.symbol-picker--color-option:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.4);
}
.symbol-picker--color-option.active {
  color: #000;
}
.symbol-picker--color-option.dark:hover {
  color: hsla(0, 0%, 100%, 0.4);
}
.symbol-picker--color-option.dark.active {
  color: #fff;
}
