.user-pic {
  width: 30px;
  height: 30px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-size: contain;
  text-align: center;
}
.user-pic .anticon {
  padding-top: 6px;
}
