.hide-left-region .editor-left-region {
    display: none;
  }
.hide-left-region .editor-main-region {
    left: 0px;
    width: calc(100% - 240px);
}
.hide-left-region .editor-bottom-region {
    left: 0px;
    width: calc(100% - 240px);
}
.editor-top-region {
    height: 50px;
    background: #fff;
    position: fixed;
    left: 0px;
    top: 0;
    z-index: 990;
    border-bottom: 1px solid #eee;
    width: 100%;
    z-index: 902;
}
.editor-top-region-mark {
  position: absolute;
  left: 8px;
  top: 10px;
  z-index: 100;
  height: 34px;
  width: 34px;
  display: block;
  background: url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center;
  background-size: contain;
  text-indent: -9999px;
}

.editor-main-region {
    width: calc(100% - 540px);
    position: fixed;
    top: 50px;
    left: 230px;
    height: calc(100% - 50px);
    overflow-x: auto;
    overflow-x: auto;
}
.editor-left-region {
    width: 230px;
    background: #f9f9f9;
    position: fixed;
    left: 0px;
    top: 50px;
    height: calc(100% - 50px);
    text-overflow: ellipsis;
    overflow-y: auto;
    overflow-x: auto;
    border-right: 1px solid #eee;
    z-index: 900;
}
.editor-right-region {
    width: 312px;
    background: #f9f9f9;
    position: fixed;
    border-left: 1px solid #eee;
    overflow-y: auto;
    right: 0;
    top: 50px;
    height: calc(100% - 50px);
    z-index: 900;
}
.editor-bottom-region {
    border-top: 1px solid #eee;
    background: #f9f9f9;
    width: calc(100% - 542px);
    position: fixed;
    left: 230px;
    bottom: -150px;
    height: 300px;
    z-index: 100;
    overflow: hidden;
    transition: bottom 0.2s ease 0s; 
}
.bottom-region-active .editor-bottom-region {
  bottom: 0px;
}
.editor-post-container {
    background: #fff;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
}
.hide-settings .editor-post-container {
  min-width: auto;
}
.editor-bottom-region .content {
    padding: 0 15px 15px 15px;
    color: #aaa;
}
.is-child-true {
    margin-left: 10px;
}
.hide-settings .editor-left-region,
.hide-settings .editor-right-region,
.hide-settings .editor-bottom-region,
.hide-settings .editor-left-menu-region,
.hide-settings .btn-data-mode,
.hide-settings .btn-top-region,
.hide-settings .btn-design-mode {
   display: none;
}
.hide-settings .editor-main-region {
    width: 100%;
    left: 0px;
}
.hide-settings .btn-toggle-settings {
   background: #1677ff;
   color: #fff;
}
.hide-settings .btn-toggle-settings:hover {
  color: #eee !important;
}
.data-ui .btn-top-region {
  display: none;
}
.data-ui .ant-dropdown-button {
  display: none;
}
 .data-ui .editor-left-menu-region {
    display: none;
 }
 .data-ui .editor-left-region {
    left: 0;
    width: 230px;
 }
.data-ui .btn-toggle-settings {
    display: none;
}
.editor-top-region  {
    text-align: center;
}
.editor-top-region a {
    color: #333;
    text-decoration: none;
    display: inline-block;
    padding: 6px 15px;
    margin-top: 9px;
    font-size: 13px;
}
.btn-data-mode {
    border-radius: 6px 0 0 6px;
    border-left: 1px solid;
    border-top: 1px solid;
    border-bottom: 1px solid; 
    border-color: #ddd;
 }
 .btn-design-mode {
    border-radius: 0 6px 6px 0;
    border-left: 1px solid; 
    border-right: 1px solid; 
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #ddd;
 }
 .btn-data-mode.active {
    background: #1677ff;
    color: #fff;
 }
 .btn-design-mode.active {
    background: #1677ff;
    color: #fff;
 }
.editor-source-container {
    padding: 40px 40px 100px 40px;
    background: #fff;
    position: relative;
}
.editor-source-container h2 {
    margin-top: 0px;
}
.editing-text.editor-ui .editor-post-container .post--content {
    padding-bottom: 500px;
}
.btn-dark-mode {
  position: absolute;
  right: 300px;
  top: 10px;
  display: none;
}
.editor-left-menu-region {
    position: fixed;
    left: 0;
    top: 50px;
    background: #fff;
    width: 50px;
    height: calc(100% - 50px);
    border-right: 1px solid #eee;
    z-index: 905;
    display: none;
}
.editor-left-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.editor-left-menu li {
    display: block;
    line-height: 2.5;
    text-align: center;
    cursor: pointer;
    font-size: 17px;
}
.editor-ui .ant-collapse {
    border-radius: 0px;
    border: none;
}
.editor-ui .ant-collapse label {
    text-transform: none;
}
.editor-ui .ant-collapse-header {
    border-radius: 0px !important;
}
.editor-ui .ant-collapse-header-text {
    font-size: 12px;
}
.editor-ui .ant-select-selector {
    font-size: 12px;
}
.editor-ui .ant-collapse .ant-input-number-input {
    font-size: 12px;
}
.editor-ui .ant-color-picker-trigger-text,
.editor-ui .ant-input-number-input,
.editor-ui .ant-input {
  font-size: 12px;
}
.editor-ui .ant-btn {
  font-size: 12px;
}
.data-ui .editor-main-region {
    width: calc(100% - 230px);
    left: 230px;
 }
 .cube-input textarea {
    background: #fff;
    border: 1px solid #ddd;
    width: 90%;
    padding: 10px;
    color: #555;
    font-family: "Courier New", Courier, monospace;
 }
 .editor-left-menu a {
    color: #111;
 }
 .editor-left-region label {
    font-size: 11px;
 }
 .component-active .component--content::after {
    content: '.';
    position: absolute;
    width: calc(100% - 10px);
    border: 2px dashed #1677ff;
    top: 2px;
    left: 2px;
    height: calc(100% - 10px);
    z-index: 1000000;
    text-indent: -9999px;
  }
  .editor-ui .map-template {
    height: calc(100vh - 50px) !important;
  }
  .editor-ui .map-template .mapboxgl-map {
    height: calc(100vh - 50px) !important;
  }
  .btn-components {
    position: absolute;
    display: block;
    left: 50px;
    line-height: 1;
    top: 2px;
    font-size: 17px !important;
  }
  .btn-post-settings {
    position: absolute;
    display: block;
    left: 85px;
    line-height: 1;
    top: 2px;
    font-size: 17px !important;
  }
  .btn-post-errors {
    position: absolute;
    display: block;
    left: 120px;
    line-height: 1;
    top: 2px;
    font-size: 17px !important;
  }
  .btn-docs {
    position: absolute;
    display: block;
    left: 155px;
    line-height: 1;
    top: 2px;
    font-size: 17px !important;
  }
  .btn-style-settings {
    position: absolute;
    right: 228px;
    top: 10px;
  }
  .btn-toggle-settings {
    position: absolute;
    right: 175px;
    top: 10px;
}
  .ant-dropdown-button {
    position: absolute;
    right: 65px;
    top: 10px;
    width: 100px;
  }
  .data-ui .btn-save-post {
    display: none;
  }
  .editor-ui .ant-collapse-content-box {
    padding-top: 20px !important;
  }
  .editor-ui .source-input-btn {
    max-width: 210px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .inline-copy {
    display: none;
  }
  .editor-ui .inline-copy {
    display: block;
  }
  .hide-settings .region-empty,
  .hide-settings .layout--region .add-component-wrapper,
  .hide-settings .inline-copy {
    display: none;
  }