.dashboard {
  background: #fff;
}
.dashboard h1 {
  color: #333;
}
.dashboard .ant-table-cell {
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 10px;
}
.ant-dropdown-menu {
  max-width: 200px !important;
}

.dashboard-menu {
  width: 200px;
  height: 100vh;
  background: #f9f9f9;
  border-right: 1px solid #eee;
  position: fixed;
  left: 70px;
  z-index: 500;
  top: 0;
}
@media (max-width: 700px) {
  .dashboard-menu {
    display: none;
  }
}
.dashboard .ant-table-cell a {
  color: #111;
}
.dashboard-menu .content {
  padding: 20px;
}
.accounts-menu {
  width: 70px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background: #f2f2f2;
  z-index: 1000;
}

.account {
  width: 45px;
  height: 45px;
  margin: 15px auto 15px auto;
  display: block;
  background-size: contain !important;
  text-indent: -9999px;
  border-radius: 8px;
}
.account--no-image {
  width: 45px;
  line-height: 3.3;
  margin: 15px auto 15px auto;
  display: block;
  background: orange;
  border-radius: 8px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}
.account--no-image:hover {
  color: #fff;
  opacity: 0.8;
}
.feed-link {
  background: url(https://assets.akuko.io/akuko-mark-drk.png) no-repeat center center;
  width: 40px;
  height: 40px;
  margin: 18px auto 15px auto;
  display: block;
  background-size: contain;
  text-indent: -9999px;
}
.my-account {
  width: 45px;
  height: 45px;
  margin: 15px auto 15px auto;
  display: block;
  border: 1px solid #ddd;
  background-size: cover;
  text-indent: -9999px;
  border-radius: 50%;
}
.my-account.no-image {
  text-indent: 0px;
  text-align: center;
  font-size: 20px;
  color: #111;
}

.my-account.no-image .anticon {
  margin-top: 8px;
}
.account:hover {
  opacity: 0.9;
}
.account-settings-menu h2 {
  color: #111;
  font-size: 16px;
  padding-bottom: 10px;
  margin: 5px 0 20px 0;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.account-settings-menu a {
  color: #111;
  display: block;
  font-size: 14px;
  padding: 10px 0 10px 0;
}
.account-settings-menu .content {
  padding: 20px 20px 20px 20px;
}
.breadcrumb {
  margin-bottom: 40px;
}
.menu-name {
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
}
.spaces {
  position: relative;
  padding: 28px 40px 40px 240px;
}
@media (max-width: 700px) {
  .spaces {
    padding: 40px 40px 40px 40px;
  }
}
@media (max-width: 600px) {
  .spaces {
    padding: 40px 40px 40px 110px;
  }
}
.space {
  margin: 10px 0;
  padding: 15px;
  height: 250px;
  background: #f9f9f9;
  border: 1px solid #eee;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.space .space-name {
  color: #111;
  position: absolute;
  font-weight: 700;
  font-size: 18px;
  top: 105px;
  left: 15px;
}
.space-settings {
  position: absolute;
  cursor: pointer;
  left: 15px;
  bottom: 15px;
  color: #777;
  z-index: 100;
}
.space-settings a {
  color: #111;
  font-size: 16px;
}
.space-image {
  background: #f2f2f2;
  height: 90px;
  position: absolute;
  border-bottom: 1px solid #ddd;
  top: 0;
  left: 0;
  width: 100%;
}
.space-form .ant-drawer-header {
  background: #fff;
  border-bottom: 1px solid #555;
  color: #aaa;
}
.space-form label {
  font-size: 12px;
}
.space-form .ant-drawer-title {
  color: #aaa;
}
.space-form .ant-drawer-content {
  background: #f9f9f9;
  color: #aaa;
}
.space-users {
  position: absolute;
  right: 30px;
  bottom: 15px;
  white-space: nowrap;
}
.more-users {
  text-align: right;
}
.space-user {
  width: 40px;
  height: 40px;
  background: #fff url(https://assets.akuko.io/akuko-user.png) no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #ccc;
  display: inline-block;
  margin-right: -20px;
  cursor: pointer;
}
.ant-alert {
  margin-bottom: 15px;
}
.table-wrapper {
  width: 100%;
  overflow-y: auto;
}

.space-logo-uploader {
  padding: 10px;
  background: #f9f9f9;
  width: 100%;
}
.btn-add-account {
  width: 45px;
  text-align: center;
  display: block;
  color: #555;
  background: #fff;
  margin: 0 auto 100px auto;
  line-height: 2;
  border-radius: 5px;
  font-size: 22px;
}
.updated {
  font-size: 12px;
  color: #999;
}
.disabled {
  color: #999;
}
